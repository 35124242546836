import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import LandingPageIndex from "../LandingPage/LandingPageIndex";
import NotFoundIndex from "../ErrorPages/NotFoundIndex";
import SinglePageIndex from "../SinglePage/SinglePageIndex";
import MainLayout from "../Layouts/MainLayout";

const AppRoute = ({
 component: Component,
 layout: Layout,
 screenProps: ScreenProps,
 ...rest
}) => (
 <Route
  {...rest}
  render={(props) => (
   <Layout screenProps={ScreenProps} {...props}>
    <Component {...props} />
   </Layout>
  )}
  isAuthed
 />
);

class App extends Component {

 render() {
  return (
   <>
    <Switch>
     <AppRoute
      path={"/"} component={LandingPageIndex} layout={MainLayout} exact />

     <AppRoute path={"/assets/:contract_address/:token_id"} component={SinglePageIndex} layout={MainLayout} />

     <Route path="*" component={NotFoundIndex} />
    </Switch>
   </>
  );
 }
}

export default App;
