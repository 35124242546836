import React, { Component } from "react";
import Base from "./components/Base";
// import { Router } from "react-router-dom";
// import { createBrowserHistory as createHistory } from "history";
// const history = createHistory();

import {
  BrowserRouter as Router,
} from 'react-router-dom';

class App extends Component {
 render() {
  return (
   <Router>
    <Base />
   </Router>
  );
 }
}

export default App;
