import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import {
  Container,
  Row,
  Col,
  Accordion,
  Button,
  ButtonGroup,
} from "react-bootstrap";
import Cartoon from "./images/cartoon-1.svg";
import "./SinglePageIndex.css";
import Keys from "./images/Keys.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faExternalLinkAlt,
  faGripVertical,
  faInfo,
  faShareAlt,
  faTag,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
import InputData from "../Data/InputData.json";
import NftLoot from "../../abis/NftLoot.json";
import Web3 from "web3";

const SinglePageIndex = () => {
  const [darkMode, setDarkMode] = useState(true);

  const [nftLoot, setNftLoot] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tokenURIs, setTokenURLs] = useState("");
  const [data, setData] = useState("");
  const [totalSupply, setTotalSupply] = useState("");

  useEffect(() => {
    loadWeb3();
  }, []);

  const { contract_address, token_id } = useParams();

  const loadWeb3 = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(
        "https://polygon-mainnet.infura.io/v3/b3eedf1cfbf5489c82fb477496327017"
      );
      // await window.ethereum.enable();
      loadBlockchainData();
      return true;
    } else if (window.web3) {
      window.web3 = new Web3(
        "https://polygon-mainnet.infura.io/v3/b3eedf1cfbf5489c82fb477496327017"
      );
      loadBlockchainData();
      return true;
    } else {
      // window.alert(
      //  "Non-Ethereum browser detected. You should consider trying MetaMask!"
      // );
      window.web3 = new Web3(
        "https://polygon-mainnet.infura.io/v3/b3eedf1cfbf5489c82fb477496327017"
      );
      loadBlockchainData();
      return true;
    }
  };

  const loadBlockchainData = async () => {
    const web3 = window.web3;
    // Network ID
    const networkId = await web3.eth.net.getId();
    const networkData = NftLoot.networks[137];
    if (networkData) {
      console.log("networkdata", networkData);
      const nftLoot = new web3.eth.Contract(
        NftLoot.abi,
        contract_address
      );
      setNftLoot(nftLoot);
      if (token_id) {
        // get the token URL
        const tokenURI = await nftLoot.methods.tokenURI(token_id).call();
        const response = await fetch(tokenURI);
        const jsonData = await response.json();
        setData(jsonData);
        setTokenURLs(tokenURI);
        setLoading(false);
      }
      const totalSupply = await nftLoot.methods.totalSupply().call();
      setTotalSupply(totalSupply);
    } else {
      window.alert("Contract not deployed to detected network.");
    }
  };



  return (
    <>
      {/* <div
        className={darkMode ? "app-light light-mode page-height" : "app-light dark-mode page-height"}
      >
        <Container>
          <div className="page-header">
            <img src={Keys} />
            <form>
              <label className="switch">
                <input type="checkbox" onClick={() => setDarkMode(!darkMode)} />
                <span className="switch-slider"></span>
              </label>
            </form>
          </div>
        </Container> */}
      <div className="single-page-sec">
        {loading ? "Loading..." :
          <Container>
            <Row>
              <Col md={5}>
                <div className="single-page-img">
                  <img src={data.image} alt="img" />
                </div>
              </Col>
              <Col md={7}>
                <div className="single-page-right-content">
                  <div className="row align-items-center">
                    <div className="col-md-8">
                      <Link className="page-title">{InputData.collection_name}</Link>
                    </div>
                    <div className="col-md-4 d-flex justify-content-end">
                      <Link className="btn-header">
                        <FontAwesomeIcon icon={faShareAlt} />
                      </Link>
                      <a className="btn-header" href={data.external_url} target="_blank">
                        <FontAwesomeIcon icon={faExternalLinkAlt} />
                      </a>
                    </div>
                  </div>
                  <h4>{data.name}</h4>
                  <div className="single-page-des">
                    <p>{data.description}</p>
                  </div>
                  <div className="single-page-btn">
                    <a href={`${InputData.opensea_link}/assets/matic/${contract_address}/${token_id}`} target="_blank">
                      <FontAwesomeIcon className="mr-10" icon={faWallet} />
                      Buy Now
                    </a>
                  </div>
                  <div className="right-side-content">
                    <h2>
                      {" "}
                      <FontAwesomeIcon className="mr-10" icon={faTag} />
                      Properties
                    </h2>
                  </div>

                  <div className="sub-content">
                    <div className="single-page-properties">
                      {data.attributes.length > 0 ? data.attributes.map(property => (
                        <div className="properties-panel text-center">
                          <h2>{property.trait_type}</h2>
                          <h3>{property.value}</h3>
                        </div>
                      )) : "No properties"}
                    </div>
                  </div>
                  <div className="right-side-content">
                    <h2>
                      {" "}
                      <FontAwesomeIcon
                        className="mr-10"
                        icon={faGripVertical}
                      />
                      About {InputData.collection_name}
                    </h2>
                  </div>
                  <div className="sub-content">
                    <p>
                      {" "}
                      {InputData.about}
                    </p>
                  </div>
                  <div className="right-side-content">
                    <h2>
                      {" "}
                      <FontAwesomeIcon className="mr-10" icon={faInfo} />
                      Details
                    </h2>
                  </div>
                  <div className="sub-content">
                    <div className="single-page-details">
                      <p>Contract Address</p>
                      <Link>{contract_address}</Link>
                    </div>
                    <div className="single-page-details">
                      <p>Token ID</p>
                      <h2>{token_id}</h2>
                    </div>
                    <div className="single-page-details">
                      <p>Token Standard</p>
                      <h2>{InputData.token_standard}</h2>
                    </div>
                    <div className="single-page-details">
                      <p>Blockchain</p>
                      <h2>{InputData.blockchain}</h2>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        }
      </div>
      {/* </div> */}
    </>
  );
};

export default SinglePageIndex;
